import '../App.css';
import {Outlet, Link} from "react-router-dom";

import logo from '../assets/images/faraday-logo.png';


function Layout() {

    return (
        <div className='layout'>
            <div className='top-menu-container'>
                <Link to='/'><img src={logo} alt='Faraday EVCS' height='10' class='logo'/></Link>

                <div className='top-menu-links-container'>
                    <Link to='/' className='top-menu-link'>SUPPORT</Link>
                    <a href='https://www.faradayevcs.com' class="top-menu-link" target='blank'>RETURN TO MAIN SITE</a>
                </div>
            </div>

            <div className='body-container'>
                <div className='body-inner-container'>
                    <Outlet/>
                </div>
            </div>

            <div className='footer-container'>
                <div className='footer-inner-container'>
                    <div className='footer-item-container'>
                        Copyright 2023 Faraday EVCS Inc.
                    </div>
                    <div className='footer-item-container'>|</div>
                    <div className='footer-item-container'>
                        <a href='https://www.faradayevcs.com/legal/website-terms' class='footer-link'>Terms of Use</a>
                    </div>
                    <div className='footer-item-container'>|</div>
                    <div className='footer-item-container'>
                        <a href='https://www.faradayevcs.com/legal/privacy' class='footer-link'>Privacy Policy</a>
                    </div>
                    <div className='footer-item-container'>|</div>
                    <div className='footer-item-container'>
                        <Link to='/warranty' className='footer-link'>Warranty</Link>
                    </div>
                </div>
            </div>

        </div>
    );

}

export default Layout;
