import '../App.css';

function NoPage (){

    return (

        <div>
            Oops, you've arrived at a dead end!  We're looking into it!
        </div>

  );

}

export default NoPage;
