
import '../App.css';

import Infobox from "../components/Infobox";

import datasheet_icon from '../assets/images/datasheet_icon.png';
import guide_icon from '../assets/images/guide_icon.png';

const docs_base_url = 'https://docs.faradayevcs.com/';
const datasheet = docs_base_url + 'datasheets/datasheet-rcd.pdf';
const user_guide = docs_base_url + 'guides/guide-rcd.pdf';


function RCD (){

    return (
        <>

            <div className="header-div">
                <h1>Support for Residual Current Device</h1>
            </div>

            <div className='infobox-container'>
                <Infobox icon={guide_icon} title='User Guide' text='Learn about the features of your residual current device.' link={user_guide} />
                <Infobox icon={datasheet_icon} title='Datasheet' text='Explore technical specifications of the residual current device.' link={datasheet} />
            </div>

        </>
  );

}

export default RCD;
