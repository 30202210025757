const Products = [
  {
    "product_name": "TALON AC Charger",
    "icon_name": "talon.png",
    "page_url": "/talon",
    "datasheet_name": "cable-datasheet.pdf",
    "user_guide_name": "cable-user-guide.pdf",
  },
  {
    "product_name": "RHINO DC Charger",
    "icon_name": "rhino.png",
    "page_url": "/rhino",
    "datasheet_name": "cable-datasheet.pdf",
    "user_guide_name": "",
  },
  {
    "product_name": 'AC Charging Cable',
    "icon_name": "cable.png",
    "page_url": "/ac-cable",
    "datasheet_name": "cable-datasheet.pdf",
    "user_guide_name": "cable-user-guide.pdf",
  },
  {
    "product_name": "Residual Current Device",
    "icon_name": "rcd.png",
    "page_url": "/rcd",
    "datasheet_name": "rcd-datasheet.pdf",
    "user_guide_name": "rcd-user-guide.pdf",
  },
];

export default Products;
