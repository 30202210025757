import '../App.css';

const Infobox = (props) => {
    const {icon, title, text} = props;
    const empty = (props.hasOwnProperty('link') ? props.empty : true);
    const link = (props.hasOwnProperty('link') ? props.link : false);

    if (empty) {
        return <div className='infobox' />
    }
    else {

        return (

            <div className='infobox'>
                <img src={icon} alt={icon} height='50'/>
                <h3>{title}</h3>
                {text}<br/>
                <br/>
                {link ? (
                    <a href={link} class='inline-link' target='blank'>Download here ></a>
                ) : (
                    <div>No download available</div>
                )}
            </div>
        )
    }
}

export default Infobox;
