import React from 'react';
import {Link} from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import '../App.css';
import '../Accordion.css';

import Products from '../products.js';


function Home() {

    const getImage = (image_name) => {
        return <img src={require(`../assets/images/${image_name}`)} alt={image_name} height='100'/>

    }

    const renderProductList = (products) => {
        return products.map(({product_name, icon_name, page_url}) =>
            <Link to={page_url} className="product-box-link">
                <div className="product-box">
                    {getImage(icon_name)}<br/>
                    <br/>
                    {product_name}
                </div>
            </Link>
        )
    }

    return (
        <>

            <div className="header-div">
                <h1>Support by product</h1>
            </div>

            <div className="product-boxes-container">
                {renderProductList(Products)}
            </div>

            <div className="header-div">
                <h1>Support by topic</h1>
            </div>

            <div className="product-boxes-container">
                <Link to='/warranty' className="product-box-link">
                    <div className="product-box">
                        <img src={require(`../assets/images/datasheet_icon.png`)} alt='Warranty information' height='40'/><br/>
                        <br/>
                        <b>WARRANTY INFORMATION</b><br/>
                        <br/>
                        All Faraday products come with a limited warranty based on your product and region.
                    </div>
                </Link>

                <Link to='/returns' className="product-box-link">
                    <div className="product-box">
                        <img src={require(`../assets/images/return_icon.png`)} alt='Returns' height='40'/><br/>
                        <br/>
                        <b>RETURNS</b><br/>
                        <br/>
                        Learn about our return policy or start a return.
                    </div>
                </Link>
            </div>

            <div className='header-div'>
                <h2>Frequently Asked Questions</h2>
            </div>

            <Accordion allowZeroExpanded={true}>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How long does it take to charge an electric vehicle?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            It depends on the charging speed of the charger, the size of the vehicle battery, and
                            the vehicle's onboard charger capability (AC charging only). Not every car can charge
                            fast, even if charger is capable of fast charging.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How much does it cost to charge an electric vehicle?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            In general, charging a vehicle can be up to 75% less expensive than filling with petrol.
                            Electricity rates vary depending on where you live and often also based on the time on
                            day. Usually electricity is cheaper at night, when there is less demand on the grid.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What are the warranty terms?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            All Faraday products come with a limited warranty based on your product and region. <Link to='/warranty' className='inline-link'>See warranty terms ></Link>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How much does it cost to charge an electric vehicle?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            In general, charging a vehicle can be up to 75% less expensive than filling with petrol.
                            Electricity rates vary depending on where you live and often also based on the time on
                            day. Usually electricity is cheaper at night, when there is less demand on the grid.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>

        </>
    );

}

export default Home;
