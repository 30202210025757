import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Talon from "./pages/Talon";
import ACCable from "./pages/ACCable";
import Rhino from "./pages/Rhino";
import RCD from "./pages/RCD";
import Warranty from "./pages/Warranty";
import Returns from "./pages/Returns";
import NoBillingConfig from "./pages/NoBillingConfig";


export default function App (){

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="*" element={<NoPage />} />
                    <Route path='/talon' element={<Talon />} />
                    <Route path='/ac-cable' element={<ACCable />} />
                    <Route path='/rhino' element={<Rhino />} />
                    <Route path='/rcd' element={<RCD />} />
                    <Route path='/warranty' element={<Warranty />} />
                    <Route path='/returns' element={<Returns />} />
                    <Route path='/no-billing-config/:id' element={<NoBillingConfig />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );

}
