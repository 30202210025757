import '../App.css';


function Warranty (){

    return (
        <>

            <div className="header-div">
                <h1>Limited Warranty</h1>
            </div>

            <p>
                Faraday EVCS Inc. (“Faraday”) warrants your product against defects in materials and workmanship
                when purchased directly from Faraday or a Faraday authorized reseller. This warranty is extended
                only to the original end-use purchaser or the person receiving the product as a gift and shall not
                be extended to any other person or transferee. The warranty is valid from the date of retail
                purchase for a period of 24 months.<br/>
                <br/>
                In the case of a covered warranty defect, Faraday will at its option:<br/>
                A. repair the product using new or refurbished parts;<br/>
                B. replace the product with an equivalent new or refurbished product; or<br/>
                C. provide a partial or full refund of the original purchase price to you in exchange for return of the
                product.<br/>
                <br/>
                This warranty does not cover defects or damage arising from improper use or maintenance, normal wear and
                tear, commercial use, accident or external causes. Unauthorized repair, modification or customization of
                your product voids this warranty.<br/>
                <br/>
                Prior to providing warranty service, Faraday may require you to provide proof of purchase details,
                including but not limited to a receipt or sales invoice. This limited warranty gives you specific rights
                that are in addition to other rights you may have under applicable laws, including your rights against
                the person who sold the Faraday product to you.<br/>
                <br/>
                To the extent permitted by law, however, this warranty is the sole and exclusive warranty provided by
                Faraday. Faraday disclaims all other warranties whether expressed or implied (including but not limited
                to any warranty of merchantability, satisfactory quality fitness for particular purpose, title, and
                non-infringement), and Faraday's liability shall be limited to the original purchase price.
            </p>

        </>
  );

}

export default Warranty;
