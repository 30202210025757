
import '../App.css';

import Infobox from "../components/Infobox";

import datasheet_icon from '../assets/images/datasheet_icon.png'
import guide_icon from '../assets/images/guide_icon.png'

const docs_base_url = 'https://docs.faradayevcs.com/';
const datasheet = docs_base_url + 'datasheets/datasheet-rhino.pdf';
const user_guide = docs_base_url + 'guides/guide-rhino.pdf';



function Rhino (){

    return (
        <>

            <div className="header-div">
                <h1>Support for RHINO DC Charger</h1>
            </div>

            <div className='infobox-container'>
                <Infobox icon={guide_icon} title='User Guide' text='Learn about the features of your charger.' link='' />
                <Infobox icon={datasheet_icon} title='Datasheet' text='Explore technical specifications of the charger.' link={datasheet} />
            </div>

        </>
  );

}

export default Rhino;
