import {Link} from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import '../App.css';
import '../Accordion.css';

import Infobox from "../components/Infobox";

import datasheet_icon from '../assets/images/datasheet_icon.png'
import guide_icon from '../assets/images/guide_icon.png'
import instructions_icon from '../assets/images/instructions_icon.png'

const docs_base_url = 'https://docs.faradayevcs.com/';
const datasheet = docs_base_url + 'datasheets/datasheet-talon.pdf';
const install_guide = docs_base_url + 'guides/guide-installation-talon.pdf';


function Talon() {

    return (
        <>

            <div className="header-div">
                <h1>Support for TALON AC Charger</h1>
            </div>

            <div className='infobox-container'>
                <Infobox icon={guide_icon} title='User Guide' text='Learn about the features of your charger.' link=''/>
                <Infobox icon={datasheet_icon} title='Datasheet' text='Explore technical specifications of the charger.'
                         link={datasheet}/>
                <Infobox icon={instructions_icon} title='Installation Requirements'
                         text='Learn about the requirements for installing your charger.'
                         link={install_guide}
                />
            </div>

            <div className='header-div'>
                <h2>Frequently Asked Questions</h2>
            </div>

            <Accordion allowZeroExpanded={true}>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What are the installation requirements for the TALON?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Great question! Check out the <Link to={install_guide} className='inline-link'>Installation
                            Requirements guide</Link>.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Does the TALON come with a cable?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Every new charger installation has very different requirements, so when you're purchasing a
                            charger you can decide whether or not to add a cable. We have cables in different power
                            rating, lengths, and colors.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can I take my charger with me if I move from my house?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Absolutely, and our team can help you with that! Contact us :)
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How do I start a charging session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            All of our chargers are pre-configured for “plug & play mode”, meaning that you can just
                            simply plug in your car and it will always start charging automatically. If you want more
                            advanced authentication features, reach out to us and we can help!
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>

        </>
    );

}

export default Talon;
