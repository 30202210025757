import '../App.css';

import { useParams } from 'react-router-dom';


function NoBillingConfig (){

    const { id } = useParams();

    return (
        <>

            <div className="header-div">
                <h1>Billing Not Configured</h1>
            </div>

            <p>
                Billing/payments have not been configured for this charger.<br />
                Please contact the owner for more information.  If you are the owner, please <a href="https://www.faradayevcs.com/contact-8">contact us</a> now.
            </p>

        </>
  );

}

export default NoBillingConfig;
