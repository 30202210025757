import React, { useState } from 'react';

import '../App.css';


function Returns (){

    const [orderNumber, setOrderNumber] = useState('');
    const [postCode, setPostCode] = useState('');
    const [isOrderNumberHovered, setIsOrderNumberHovered] = useState(false);
    const [isPostCodeHovered, setIsPostCodeHovered] = useState(false);

    const handleOrderNumberChange = (event) => {
        setOrderNumber(event.target.value);
    }

    const handlePostCodeChange = (event) => {
        setPostCode(event.target.value);
    }

    const startReturn = () => {
        // Here you can handle the start return logic.
        console.log(`Starting return for order ${orderNumber} from post code ${postCode}`);
    }

    return (
        <>

            <div className="header-div">
                <h1>Returns</h1>
            </div>

            <p>
                <b>Eligibility for Return</b><br />
                Products may be returned within 30 days of the delivery date. The product must be unused, in the same condition that you received it, and must also be in the original packaging. Products that have been used or damaged, or products not in their original packaging, are not eligible for return.<br />
                <br />
                <b>Shipping</b><br />
                You will be responsible for paying for your own shipping costs for returning the item. Shipping costs are non-refundable. We recommend using a trackable shipping service and purchasing shipping insurance. We cannot guarantee that we will receive your returned item.<br />
                <br />
                <b>Inspection</b><br />
                Once we receive your return, we will inspect the item and notify you of the status of your refund. If your return is approved, we will initiate a refund to your original method of payment.<br />
                <br />
                <b>Timing</b><br />
                You can expect the refund to be processed within 14 working days of our receipt of the returned product.<br />
                <br />
                <b>Restocking Fee</b><br />
                Please note that a restocking fee of 10% will be deducted from your refund. This fee covers the cost of processing the return and restocking the product.
            </p>
            <br />
            <br />
            <div className="returns-container" style={styles.container}>

                <input
                    type="text"
                    placeholder="Order Number"
                    value={orderNumber}
                    onChange={handleOrderNumberChange}
                    onMouseEnter={() => setIsOrderNumberHovered(true)}
                    onMouseLeave={() => setIsOrderNumberHovered(false)}
                    style={
                        isOrderNumberHovered
                            ? { ...styles.input, ...styles.hover }
                            : styles.input
                    }
                />

                <input
                    type="text"
                    placeholder="Post Code"
                    value={postCode}
                    onChange={handlePostCodeChange}
                    onMouseEnter={() => setIsPostCodeHovered(true)}
                    onMouseLeave={() => setIsPostCodeHovered(false)}
                    style={
                        isPostCodeHovered
                            ? { ...styles.input, ...styles.hover }
                            : styles.input
                    }
                />

                <br />

                <button onClick={startReturn} style={styles.button}>
                    Start a return
                </button>
            </div>

        </>
  );

}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        width: '50%',
    },
    input: {
        width: '60%',
        padding: '15px',
        marginBottom: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '18px',
    },
    button: {
        width: '65%',
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: '1px solid #4CAF50',
        cursor: 'pointer',
        fontSize: '18px',
    },
    hover: {
        border: '1px solid #4CAF50',
    }
};

export default Returns;
