import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import '../App.css';
import '../Accordion.css';

import Infobox from "../components/Infobox";

import datasheet_icon from '../assets/images/datasheet_icon.png'
import guide_icon from '../assets/images/guide_icon.png'

const docs_base_url = 'https://docs.faradayevcs.com/';
const datasheet = docs_base_url + 'datasheets/datasheet-ac-cable.pdf';
const user_guide = docs_base_url + 'guides/guide-ac-cable.pdf';


function ACCable() {

    return (
        <>

            <div className="header-div">
                <h1>Support for AC Charging Cable</h1>
            </div>

            <div className='infobox-container'>
                <Infobox icon={guide_icon} title='User Guide' text='Learn about the features of your charging cable.'
                         link={user_guide}/>
                <Infobox icon={datasheet_icon} title='Datasheet'
                         text='Explore technical specifications of the charging cable.' link={datasheet}/>
            </div>

            <div className='header-div'>
                <h2>Frequently Asked Questions</h2>
            </div>

            <Accordion allowZeroExpanded={true}>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can I use my own cable with the TALON?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            The TALON requires a tethered cable in order to operate - you are more than welcome to have
                            your own cable installed with the TALON as long as the cable is compatible with the current
                            rating of the TALON. We do however recommend choosing one of our AC charging cables when
                            purchasing your TALON in order to ensure optimal charging speed and safety.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can I use Faraday cables with other chargers?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Yes, you can definitely use our cables with other compatible EV chargers, although we highly
                            recommend using our products within our ecosystem, for the best possible experience and
                            safety.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>

        </>
    );

}

export default ACCable;
